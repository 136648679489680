import { type GetterTree } from 'vuex'
import { indexOf } from 'lodash' /* eslint-disable-line import/named */
import { RootState } from '../state'
import { TgpState } from '../tgp/state'
import { STEPS, type Step, type StepSection, type StepState } from './state'

export const activeSteps = (section: StepSection, tgpState: TgpState) =>
  STEPS[section]
    .filter((step) => {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const [_stepName, condition] = [step].flat() as [
        string,
        (tgpState: TgpState) => boolean | undefined
      ]

      if (condition === undefined) {
        return true
      }

      return condition(tgpState)
    })
    .map((step) => ([step].flat() as unknown as string[])[0])

const currentStepNumber = (
  section: StepSection,
  step: Step,
  tgpState: TgpState
) => indexOf(activeSteps(section, tgpState), step)

const nextStep = (section: StepSection, step: Step, tgpState: TgpState) =>
  activeSteps(section, tgpState)[currentStepNumber(section, step, tgpState) + 1]

const previousStep = (section: StepSection, step: Step, tgpState: TgpState) => {
  // page 'offers' is not defined in the steps, but it's always the previous page from 'souscription'
  if (step === 'souscription-assure-principal') return 'offres'

  // path of 'souscription' as change but not is steps name
  return activeSteps(section, tgpState)[
    currentStepNumber(section, step, tgpState) - 1
  ]
}

const getters: GetterTree<StepState, RootState> = {
  firstStep: (state, _getters, { tgp }) =>
    activeSteps(state.currentSection, tgp)[0],
  currentStep: (state) => state.currentStep,
  currentStepNumber: (state, _getters, { tgp }) =>
    currentStepNumber(state.currentSection, state.currentStep, tgp) + 1,
  nbSteps: (state, _getters, { tgp }) =>
    activeSteps(state.currentSection, tgp).length,
  nextStep: (state, _getters, { tgp }) =>
    nextStep(state.currentSection, state.currentStep, tgp),
  previousStep: (state, _getters, { tgp }) =>
    previousStep(state.currentSection, state.currentStep, tgp),
}

export default getters
