/* eslint-disable-next-line import/named */
import _, { isNil, maxBy, omitBy, reduce, some, sortBy, take } from 'lodash'
import {
  type Act,
  type CategoryDetails,
  type Offer,
  type OfferContent,
  type OfferWithContent,
  type Rate,
  type RepresentativeLevel,
  type SubCategory,
} from '~/types/offers'

const requiredKeys = ['globalPrice', 'currency', 'globalPriceWithoutOption']
// if rate has key:
// - globalPrice
// - currency
// - globalPriceWithoutOption
// and according business rules defined here: https://apriltechnologies.atlassian.net/browse/TS2-41
export const isEligible = (rate: Rate, mainInsuredAge: number) =>
  // product having required informations
  _.intersection(requiredKeys, Object.keys(rate)).length ===
    requiredKeys.length &&
  // product excluded: APRIL Santé PrimoV2
  // rate.marketingProductId !== 'APRIL_ASP_PMOV2' &&
  // product excluded: APRIL Santé Primo
  // rate.marketingProductId !== 'APRIL_ASP_PMO' &&
  // product excluded: APRIL Santé Eco
  !(
    ['APRIL_ASP_ECOV2', 'APRIL_ASP_ECOV3'].includes(rate.marketingProductId) &&
    ['ECO_01|01', 'ECO_02|02', 'ECO_03|03', 'ECO_03|04', 'ECO_03|05'].includes(
      rate.levelCode
    )
  ) &&
  // product excluded: APRIL Santé Tranquilité only if older than 56
  !(
    rate.marketingProductId.includes('APRIL_ASP_TRAV3') && mainInsuredAge < 56
  ) &&
  // product excluded: APRIL Santé ZEN only if older than 25
  !(rate.marketingProductId.includes('APRIL_ASP_ZEN') && mainInsuredAge < 25) &&
  // product excluded: APRIL Global plus only if main insured is older than 58
  !(rate.marketingProductId.includes('APRIL_ASP_GLO') && mainInsuredAge < 58)

export const CATEGORIES = [
  'SOINS_COURANTS',
  'HOSPIT_CONV',
  'OPTIQUE',
  'DENTAIRE',
  'AUDIO',
  'CONFORT_MED_NAT',
  'AVANTAGES',
] as const

export type Category = (typeof CATEGORIES)[number]

export const categoryKeysFromPayload = (payload: { [key: string]: string }[]) =>
  ({
    ...CATEGORIES.reduce(
      (accumulator: { [key in Category]: string[] }, category: Category) => {
        const labels = payload[0]
        const keys = Object.keys(labels)
          .filter((k) => _.startsWith(k, category) && labels[k].length)
          .sort()

        accumulator[category] = keys as SubCategory[]

        return accumulator
      },
      {} as {
        [key in Category]: SubCategory[]
      }
    ),
  } as unknown as { [key in Category]: string[] })

export const categoryLabelsFromPayload = (
  payload: { [key: string]: string }[]
) =>
  ({
    ...Object.fromEntries(
      Object.entries(categoryKeysFromPayload(payload)).map(
        ([key, values]: [string, string[]]) => [
          key,
          values.map((value) => payload[0][value]),
        ]
      )
    ),
  } as unknown as { [x: string]: string[] })

const CATEGORY_CONTENT: { [key: string]: { label: string; icon?: string } } = {
  SOINS_COURANTS: { label: 'Soins courants', icon: 'care-circle-fill' },
  HOSPIT_CONV: {
    label: 'Hospitalisation',
    icon: 'hospitalisation-circle-fill',
  },
  DENTAIRE: { label: 'Dentaire', icon: 'dental-circle-fill' },
  OPTIQUE: { label: 'Optique', icon: 'optic-circle-fill' },
  AUDIO: { label: 'Aides auditives', icon: 'hearing-circle-fill' },
  CONFORT_MED_NAT: { label: 'Médecines douces', icon: 'medicine-circle-fill' },
  AVANTAGES: { label: 'Avantages', icon: undefined },
}

const REPRESENTATIVE_MAPPING: { [key in RepresentativeLevel]: string } = {
  '1': 'Minimum',
  '2': 'Partiel',
  '3': 'Renforcé',
  '4': 'Maximum',
}

export const representativeName = (level: RepresentativeLevel) =>
  REPRESENTATIVE_MAPPING[level]

export const representativeLevelFromActs = (
  acts: Act[],
  category: Category
) => {
  const act = acts.find((act) => act.representative)

  if (act) {
    switch (category) {
      case 'HOSPIT_CONV':
      case 'SOINS_COURANTS':
      case 'DENTAIRE':
      case 'OPTIQUE':
        if (act.representativeLevel)
          return representativeName(act.representativeLevel)
        break

      case 'AUDIO':
        if (act.actValue === 'Non') return 'Minimum'

        if (
          ((act.actValue === '100' && act.actUnit === '%') ||
            act.actValue === '100%') &&
          act.actDescription?.includes('limité à 100%')
        )
          return 'Renforcé'

        if (
          (act.actValue !== '100' && act.actUnit === '%') ||
          (act.actValue === '100' && act.actUnit !== '%') ||
          act.actValue !== '100%'
        )
          return 'Renforcé'

        // in all other cases
        return 'Minimum'

      case 'CONFORT_MED_NAT':
        if (act.actValue === 'Non') return 'Non'
        if (act.actValue) return 'Oui'
        break

      case 'AVANTAGES':
        break
    }
  }

  return 'Non'
}

export const columnName = (offer: Offer, category: Category) => {
  if (category === 'AVANTAGES') return 'Oui'

  const warranty = offer.warrantiesSummary.find(
    (cover) => cover.coverId === category
  )
  let representativeLevel: string | undefined

  if (!warranty) return

  const subcovers = warranty.subCovers

  if (subcovers) {
    const subcover = subcovers.find((subCover) => subCover.acts)

    if (subcover) {
      const acts = subcover.acts

      if (acts)
        representativeLevel = representativeLevelFromActs(acts, category)
    }
  }

  const acts = warranty.acts

  if (acts) representativeLevel = representativeLevelFromActs(acts, category)

  return representativeLevel
}

const filterAdvantages = (
  offersContent: OfferWithContent[],
  categories: { [key in Category]: string[] },
  context: {
    mainInsuredAge: number
  }
) => {
  const avantageSubCategories = categories.AVANTAGES as SubCategory[]

  avantageSubCategories.forEach((subCategory: SubCategory) => {
    if (
      some(
        offersContent.map((offer) => offer.content),
        [subCategory, 'O']
      )
    ) {
      if (subCategory === 'AVANTAGES_4') {
        // remove advantage when main insured is less than 65 or more than 70
        if (context.mainInsuredAge < 65 || context.mainInsuredAge > 70)
          offersContent.forEach((offer) => {
            if (!offer.content) return

            delete offer.content[subCategory]
          })
      }

      return
    }

    // remove advantage when false for all offers
    offersContent.forEach((offer) => {
      if (!offer.content) return

      delete offer.content[subCategory]
    })
  })
}

export const formatLevelCodeToFormule = (
  levelCode: string,
  position?: 1 | 2
) => {
  const matchLevel = /^0(\d)$/.exec(levelCode)

  if (matchLevel) return matchLevel[1]

  const matchEcoLevel = /^\w+_0(\d)\|0(\d)$/.exec(levelCode)

  if (matchEcoLevel)
    if (position) return matchEcoLevel[position]
    else return matchEcoLevel.slice(1, 3).join('-')
}

const renameAvantagesColumns = (categoryDetails: CategoryDetails[]) => {
  const avantages = categoryDetails.find(
    (category) => category.category === 'AVANTAGES'
  )

  if (!avantages) return categoryDetails

  const numValues = avantages.details[0].values.length
  const columns = Array.from({ length: numValues }).map((_, index) => {
    const atLeastOneTrueAtIndex = reduce(
      avantages.details,
      (acc, { values }) => acc || (values[index] as boolean),
      false
    )

    return atLeastOneTrueAtIndex ? 'Oui' : 'Non'
  })

  avantages.columns = columns

  return categoryDetails
}

// deprecated function but kept for reference
// const fillFormules = (offersContent: OfferWithContent[]) => {
//   const formuleSubCategories = ['FORMULE_1', 'FORMULE_2'] as SubCategory[]

//   formuleSubCategories.forEach((subCategory: SubCategory) => {
//     switch (subCategory) {
//       case 'FORMULE_1':
//         offersContent.forEach((offer) => {
//           if (!offer.content) return

//           const level = formatLevelCodeToFormule(offer.levelCode, 1)
//           offer.content.FORMULE_1 = level && `Niveau ${level}`
//         })

//         break

//       case 'FORMULE_2':
//         offersContent.forEach((offer) => {
//           if (!offer.content) return

//           const level = formatLevelCodeToFormule(offer.levelCode, 2)
//           offer.content.FORMULE_2 = level && `Niveau ${level}`
//         })

//         break

//       default:
//         break
//     }
//   })
// }

export const categoryDetailsPayload = (
  offers: Offer[],
  contentPayload: OfferContent[],
  labelsPayload: { [key: string]: string }[],
  context: {
    mainInsuredAge: number
  }
): CategoryDetails[] => {
  const categories = categoryKeysFromPayload(labelsPayload)
  const categoryLabels = categoryLabelsFromPayload(labelsPayload)
  const offersContent = offers.map((offer) => ({
    ...offer,
    content: {
      ...contentPayload.find(
        (payload) =>
          payload.marketingProductId === offer.marketingProductId &&
          payload.levelCode === offer.levelCode
      ),
    },
  }))

  filterAdvantages(offersContent as OfferWithContent[], categories, context)

  const categoryDetails = Object.entries(categories)
    .map(([category, subCategories]) => ({
      ...CATEGORY_CONTENT[category],
      category: category as Category,
      columns: offers.map((offer) => columnName(offer, category as Category)),
      details: subCategories
        .map((subCategory, idx) => ({
          subCategory,
          label: categoryLabels[category][idx],
          values: offersContent.map(({ content }) => {
            const text = content && (content as any)[subCategory]

            // return boolean if O or N
            if (['O', 'N'].includes(text)) return text === 'O'

            return text
          }),
        }))
        // for category AVANTAGES, empty sublines must be removed
        .filter(({ values }) => category !== 'AVANTAGES' || some(values)),
    }))
    // remove empty lines (like AVANTAGES when no subline present)
    .filter(({ details }) => some(details))
    // remove undefined values
    .map(
      (categoryDetails) =>
        omitBy(categoryDetails, isNil) as unknown as CategoryDetails
    )

  return renameAvantagesColumns(categoryDetails)
}

export const getLabelFromVectorID = (
  labelArray: { UTM_Content: number; Label_comparateur: string }[],
  vectorId: number
) => {
  const searchedLabel = labelArray.find(
    (element) => element.UTM_Content === vectorId
  )

  if (searchedLabel) {
    return searchedLabel.Label_comparateur
  } else {
    throw new Error('Label not found')
  }
}

export const getOffersWithComparator = (
  comparatorOffer: Offer,
  offers: Offer[]
) => {
  const lowerPriceOffers = take(sortBy(offers, 'globalPriceWithoutOption'), 3)
  const containsComparatorOffer = lowerPriceOffers.some(
    (offer) =>
      offer.levelCode === comparatorOffer.levelCode &&
      offer.marketingProductId === comparatorOffer.marketingProductId
  )

  if (!containsComparatorOffer) {
    const maxPriceOffer = maxBy(
      lowerPriceOffers,
      (offer) => offer.globalPriceWithoutOption
    )

    if (lowerPriceOffers.length > 2) {
      const offersWithoutMaxPrice = lowerPriceOffers.filter(
        (item) =>
          !(
            item.marketingProductId === maxPriceOffer?.marketingProductId &&
            item.levelCode === maxPriceOffer?.levelCode
          )
      )

      return sortBy(
        [...offersWithoutMaxPrice, comparatorOffer],
        'globalPriceWithoutOption'
      )
    } else {
      return sortBy(
        [...lowerPriceOffers, comparatorOffer],
        'globalPriceWithoutOption'
      )
    }
  }

  return lowerPriceOffers
}

export const filterOffers = (offers: Offer[]) => {
  const offerFiltering = offers.filter((offer: any) => {
    // Checks if the offer contains more than one option or no options
    if (offer.options?.length > 1 || offer.options === undefined) {
      return true
    }

    // If the offer contains only one option, check if optionId is different from 'GarantieRachat'
    if (
      offer.options?.length === 1 &&
      offer.options[0].optionId !== 'GarantieRachat'
    ) {
      return true
    }

    // If offers contains all one option with optionId 'GarantieRachat'
    const onlyGarantieRachat = offers.every(
      (offer: any) =>
        offer.options?.length === 1 &&
        offer.options[0].optionId === 'GarantieRachat'
    )
    if (offer.options?.length === 1 && onlyGarantieRachat) {
      return true
    }

    // Excludes the offer if it contains only one option with optionId 'GarantieRachat'
    return false
  })

  return offerFiltering
}

export const extractProductNameWithLevel = (
  productLabel: string,
  levelCode: string,
  levelWord: string
) => {
  const offerTitle = productLabel
    .replace('APRIL', '')
    .replace('Santé', '')
    .trim()

  const levels = `${levelWord} ${formatLevelCodeToFormule(
    levelCode,
    1
  )}-${formatLevelCodeToFormule(levelCode, 2)}`

  return `${offerTitle} ${levels}`
}
