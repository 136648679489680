// This file mount the module UI for the Vuex store
import * as adUI from 'UI/store/ui'
import { plugin as adPlugin } from 'UI/store/ui/plugin'

const plugins = [adPlugin]

export { plugins }

export const modules = {
  ui: adUI,
}
