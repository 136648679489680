import { type TgpState } from '~/store/tgp/state'
import { type OfferContent } from '~/types/offers'
import { type ComparatorPage, type OfferPage } from '~/types/navigation'
import { dateEffetIsValid, isValidHour } from '~/utils/date'
import { stringToBoolean } from '~/utils/form'
import { PrismicState } from '~/store/prismic/state'

export type Router = {
  history: {
    current: {
      query: any
    }
  }
  push: ({ path, query }: { path: string; query: any }) => void
}

// list here all query params that have to be kept into the URL during navigation
const FORWARDED_QUERY_PARAMS = [
  'gclid',
  'token',
  'utm_medium',
  'utm_content',
  'utm_source',
  'utm_campaign',
]

const forwardParams = (router: Router) =>
  Object.keys(router.history.current.query)
    .filter((key) => FORWARDED_QUERY_PARAMS.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = router.history.current.query[key]
      return obj
    }, {})

export const goTo = (router: Router, path: string) => {
  router.push({ path, query: forwardParams(router) })
}

export const optionsAvailable = (offer: OfferContent) =>
  offer.options?.some((option) => option.optionId !== 'GarantieRachat')

export const rachatLimitationAvailable = (offer: OfferContent) =>
  offer.options?.some((option) => option.optionId === 'GarantieRachat')

export const madelinAvailable = (tgpState: TgpState) =>
  // here, if regime is SS, !(tgpState.regime === 'TNS') is already true
  tgpState.regime === 'TNS' ||
  (tgpState.regime === 'SS' && tgpState.statutPro === 'ParamedicalNonSalarie')

export const isFromComparator = (tgpState: TgpState) =>
  !!tgpState.comparatorOffer

export const comparatorMissingDataPages = [
  'comparateur-date-effet',
  'comparateur-couverture-actuelle',
]

export const offersRouting = (
  currentPage: OfferPage | ComparatorPage | 'souscription-assure-principal',
  tgpState: TgpState,
  previous = false
): OfferPage | ComparatorPage | 'souscription-assure-principal' => {
  // according to specifications from https://apriltechnologies.atlassian.net/browse/TS2-26
  switch (currentPage) {
    case 'offres':
    case 'souscription':
      if (isFromComparator(tgpState)) {
        // case 2
        return 'comparateur-couverture-actuelle'
      }

      // just to be sure, go to panier anyway
      return 'souscription-assure-principal'

    case 'souscription-assure-principal':
      if (previous) {
        return 'offres'
      }

      // just to be sure, go to panier anyway
      return 'souscription-assure-principal'

    default:
      // just to be sure, go to panier anyway
      return 'souscription-assure-principal'
  }
}

export const comparatorRouting = (
  currentPage: ComparatorPage,
  tgpState: TgpState,
  isPrevious = false,
  stepsData: PrismicState['stepsData']
): OfferPage | ComparatorPage | 'souscription-assure-principal' => {
  const offer = tgpState.selectedOffer
  if (!offer) return 'offres'

  const hasIndividualPolicyInsurance = stringToBoolean(
    tgpState.projectSituation?.hasIndividualPolicyInsurance
  )
  const isCancelableInsurance = stringToBoolean(
    tgpState.projectSituation?.isCancelableInsurance
  )
  const valideHour = isValidHour(stepsData.dateEffetDays) ? 1 : 2

  if (isPrevious) {
    switch (currentPage) {
      case 'comparateur-couverture-actuelle':
        return ('souscription/' + tgpState.projectId) as OfferPage
      case 'comparateur-date-effet':
        return 'comparateur-couverture-actuelle'
      case 'comparateur-madelin':
        return 'comparateur-date-effet'

      default:
        return ('souscription/' + tgpState.projectId) as OfferPage
    }
  } else {
    switch (currentPage) {
      case 'comparateur-couverture-actuelle':
        if (
          tgpState.dateEffet &&
          dateEffetIsValid(
            tgpState.dateEffet,
            hasIndividualPolicyInsurance,
            isCancelableInsurance,
            valideHour
          )
        ) {
          if (madelinAvailable(tgpState)) {
            return 'comparateur-madelin'
          } else {
            return 'souscription-assure-principal'
          }
        }

        return 'comparateur-date-effet'

      case 'comparateur-date-effet':
        if (madelinAvailable(tgpState)) {
          return 'comparateur-madelin'
        } else {
          return 'souscription-assure-principal'
        }

      case 'comparateur-madelin':
        return 'souscription-assure-principal'
    }
  }
}
