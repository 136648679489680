import { render, staticRenderFns } from "./offers.vue?vue&type=template&id=5da658b5&scoped=true"
import script from "./offers.vue?vue&type=script&setup=true&lang=ts"
export * from "./offers.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./offers.vue?vue&type=style&index=0&id=5da658b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da658b5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopinMaintenance: require('/vercel/path0/components/PopinMaintenance.vue').default,FoundationsUiWrapper: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-wrapper/ui-wrapper.vue').default,AprilBody: require('/vercel/path0/components/AprilBody.vue').default})
