import { type ActionTree } from 'vuex'
import { type TgpState } from './state'
import { type Address, type Contact } from '~/types/global'
import { type Offer } from '~/types/offers'

const actions: ActionTree<TgpState, TgpState> = {
  counterDown({ state, commit }) {
    const counter = state.tgpCounter
    commit('setCounter', counter - 1)
  },
  counterUp({ state, commit }) {
    const counter = state.tgpCounter
    commit('setCounter', counter + 1)
  },
  setAdresse({ commit }, payload: Address) {
    commit('setAdresse', payload)
  },
  setBasicHealthCovers({ commit }, payload) {
    commit('setBasicHealthCovers', payload)
  },
  setContact({ commit }, payload: Contact) {
    commit('setContact', payload)
  },
  setCommercialOpportunityId({ commit }, payload) {
    commit('setCommercialOpportunityId', payload)
  },
  setCreateProjectDate({ commit }, payload) {
    commit('setCreateProjectDate', payload)
  },
  setContributorId({ commit }, payload) {
    commit('setContributorId', payload)
  },
  setChild0Birthdate({ commit }, payload) {
    commit('setChild0Birthdate', payload)
  },
  setChild0MandatoryScheme({ commit }, payload) {
    commit('setChild0MandatoryScheme', payload)
  },
  setChild0ContributorId({ commit }, payload) {
    commit('setChild0ContributorId', payload)
  },
  setChild0CivilityRefId({ commit }, payload) {
    commit('setChild0CivilityRefId', payload)
  },
  setChild0FirstName({ commit }, payload) {
    commit('setChild0FirstName', payload)
  },
  setChild0Surname({ commit }, payload) {
    commit('setChild0Surname', payload)
  },
  setChild0FrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChild0FrenchSocialSecurityNumber', payload)
  },
  setChild0AttachmentContributorId({ commit }, payload) {
    commit('setChild0AttachmentContributorId', payload)
  },
  setChild0HasAttachedSocialSecurity({ commit }, payload) {
    commit('setChild0HasAttachedSocialSecurity', payload)
  },
  setChild1Birthdate({ commit }, payload) {
    commit('setChild1Birthdate', payload)
  },
  setChild1MandatoryScheme({ commit }, payload) {
    commit('setChild1MandatoryScheme', payload)
  },
  setChild1ContributorId({ commit }, payload) {
    commit('setChild1ContributorId', payload)
  },
  setChild1CivilityRefId({ commit }, payload) {
    commit('setChild1CivilityRefId', payload)
  },
  setChild1FirstName({ commit }, payload) {
    commit('setChild1FirstName', payload)
  },
  setChild1Surname({ commit }, payload) {
    commit('setChild1Surname', payload)
  },
  setChild1FrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChild1FrenchSocialSecurityNumber', payload)
  },
  setChild1AttachmentContributorId({ commit }, payload) {
    commit('setChild1AttachmentContributorId', payload)
  },
  setChild1HasAttachedSocialSecurity({ commit }, payload) {
    commit('setChild1HasAttachedSocialSecurity', payload)
  },
  setChild2Birthdate({ commit }, payload) {
    commit('setChild2Birthdate', payload)
  },
  setChild2MandatoryScheme({ commit }, payload) {
    commit('setChild2MandatoryScheme', payload)
  },
  setChild2ContributorId({ commit }, payload) {
    commit('setChild2ContributorId', payload)
  },
  setChild2CivilityRefId({ commit }, payload) {
    commit('setChild2CivilityRefId', payload)
  },
  setChild2FirstName({ commit }, payload) {
    commit('setChild2FirstName', payload)
  },
  setChild2Surname({ commit }, payload) {
    commit('setChild2Surname', payload)
  },
  setChild2FrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChild2FrenchSocialSecurityNumber', payload)
  },
  setChild2AttachmentContributorId({ commit }, payload) {
    commit('setChild2AttachmentContributorId', payload)
  },
  setChild2HasAttachedSocialSecurity({ commit }, payload) {
    commit('setChild2HasAttachedSocialSecurity', payload)
  },
  setChild3Birthdate({ commit }, payload) {
    commit('setChild3Birthdate', payload)
  },
  setChild3MandatoryScheme({ commit }, payload) {
    commit('setChild3MandatoryScheme', payload)
  },
  setChild3ContributorId({ commit }, payload) {
    commit('setChild3ContributorId', payload)
  },
  setChild3CivilityRefId({ commit }, payload) {
    commit('setChild3CivilityRefId', payload)
  },
  setChild3FirstName({ commit }, payload) {
    commit('setChild3FirstName', payload)
  },
  setChild3Surname({ commit }, payload) {
    commit('setChild3Surname', payload)
  },
  setChild3FrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChild3FrenchSocialSecurityNumber', payload)
  },
  setChild3AttachmentContributorId({ commit }, payload) {
    commit('setChild3AttachmentContributorId', payload)
  },
  setChild3HasAttachedSocialSecurity({ commit }, payload) {
    commit('setChild3HasAttachedSocialSecurity', payload)
  },
  setChild4Birthdate({ commit }, payload) {
    commit('setChild4Birthdate', payload)
  },
  setChild4MandatoryScheme({ commit }, payload) {
    commit('setChild4MandatoryScheme', payload)
  },
  setChild4ContributorId({ commit }, payload) {
    commit('setChild4ContributorId', payload)
  },
  setChild4CivilityRefId({ commit }, payload) {
    commit('setChild4CivilityRefId', payload)
  },
  setChild4FirstName({ commit }, payload) {
    commit('setChild4FirstName', payload)
  },
  setChild4Surname({ commit }, payload) {
    commit('setChild4Surname', payload)
  },
  setChild4FrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChild4FrenchSocialSecurityNumber', payload)
  },
  setChild4AttachmentContributorId({ commit }, payload) {
    commit('setChild4AttachmentContributorId', payload)
  },
  setChild4HasAttachedSocialSecurity({ commit }, payload) {
    commit('setChild4HasAttachedSocialSecurity', payload)
  },
  setChild5Birthdate({ commit }, payload) {
    commit('setChild5Birthdate', payload)
  },
  setChild5MandatoryScheme({ commit }, payload) {
    commit('setChild5MandatoryScheme', payload)
  },
  setChild5ContributorId({ commit }, payload) {
    commit('setChild5ContributorId', payload)
  },
  setChild5CivilityRefId({ commit }, payload) {
    commit('setChild5CivilityRefId', payload)
  },
  setChild5FirstName({ commit }, payload) {
    commit('setChild5FirstName', payload)
  },
  setChild5Surname({ commit }, payload) {
    commit('setChild5Surname', payload)
  },
  setChild5FrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChild5FrenchSocialSecurityNumber', payload)
  },
  setChild5AttachmentContributorId({ commit }, payload) {
    commit('setChild5AttachmentContributorId', payload)
  },
  setChild5HasAttachedSocialSecurity({ commit }, payload) {
    commit('setChild5HasAttachedSocialSecurity', payload)
  },
  setChildCounter({ commit }, payload) {
    commit('setChildCounter', payload)
  },
  setComparatorOffer({ commit }, payload) {
    commit('setComparatorOffer', payload)
  },
  setCurrentInsurer({ commit }, payload) {
    commit('setCurrentInsurer', payload)
  },
  setEtape({ commit }, payload) {
    commit('setEtape', payload)
  },
  setDateChanged({ commit }, payload) {
    commit('setDateChanged', payload)
  },
  setDateEffet({ commit }, payload) {
    commit('setDateEffet', payload)
  },
  setDateNaissance({ commit }, payload) {
    commit('setDateNaissance', payload)
  },
  setDeductibilityMadelinLaw({ commit }, payload) {
    commit('setDeductibilityMadelinLaw', payload)
  },
  setFreelancer({ commit }, payload) {
    commit('setFreelancer', payload)
  },
  setHasCreatedNeeds({ commit }, payload) {
    commit('setHasCreatedNeeds', payload)
  },
  setOfferId({ commit }, payload) {
    commit('setOfferId', payload)
  },
  setOfferIdPex({ commit }, payload) {
    commit('setOfferIdPex', payload)
  },
  setOrderId({ commit }, payload) {
    commit('setOrderId', payload)
  },
  setOrderIdPex({ commit }, payload) {
    commit('setOrderIdPex', payload)
  },
  setOptionId0({ commit }, payload) {
    commit('setOptionId0', payload)
  },
  setOptionLabel0({ commit }, payload) {
    commit('setOptionLabel0', payload)
  },
  setOptionLevel0({ commit }, payload) {
    commit('setOptionLevel0', payload)
  },
  setOptionPrice0({ commit }, payload) {
    commit('setOptionPrice0', payload)
  },
  setOptionId1({ commit }, payload) {
    commit('setOptionId1', payload)
  },
  setOptionLabel1({ commit }, payload) {
    commit('setOptionLabel1', payload)
  },
  setOptionLevel1({ commit }, payload) {
    commit('setOptionLevel1', payload)
  },
  setOptionPrice1({ commit }, payload) {
    commit('setOptionPrice1', payload)
  },
  setIban({ commit }, payload) {
    commit('setIban', payload)
  },
  setPaymentDay({ commit }, payload) {
    commit('setPaymentDay', payload)
  },
  setPeopleComposition({ commit }, payload) {
    commit('setPeopleComposition', payload)
  },
  setPolicyNumber({ commit }, payload) {
    commit('setPolicyNumber', payload)
  },
  setPeriodicity({ commit }, payload) {
    commit('setPeriodicity', payload)
  },
  setProjectSituation({ commit }, payload) {
    commit('setProjectSituation', payload)
  },
  setProjectId({ commit }, payload) {
    commit('setProjectId', payload)
  },
  setProjectIdPex({ commit }, payload) {
    commit('setProjectIdPex', payload)
  },
  setProjectMarketingId({ commit }, payload) {
    commit('setProjectMarketingId', payload)
  },
  setProjectType({ commit }, payload) {
    commit('setProjectType', payload)
  },
  setRegime({ commit }, payload) {
    commit('setRegime', payload)
  },
  setSelectedOffer({ state, commit }, offer: Offer) {
    // reset options
    commit('setFreelancer', undefined)

    if (
      state.selectedOffer?.marketingProductId !== offer.marketingProductId ||
      state.selectedOffer?.levelCode !== offer.levelCode
    ) {
      commit('setOptionId0', undefined)
      commit('setOptionLevel0', undefined)
      commit('setOptionLabel0', undefined)
      commit('setOptionPrice0', 0)
      commit('setOptionId1', undefined)
      commit('setOptionLevel1', undefined)
      commit('setOptionLabel1', undefined)
      commit('setOptionPrice1', 0)
    }

    commit('setSelectedOffer', offer)
  },
  setSpouseBirthdate({ commit }, payload) {
    commit('setSpouseBirthdate', payload)
  },
  setSpouseContributorId({ commit }, payload) {
    commit('setSpouseContributorId', payload)
  },
  setSpouseMandatoryScheme({ commit }, payload) {
    commit('setSpouseMandatoryScheme', payload)
  },
  setStatutPro({ commit }, payload) {
    commit('setStatutPro', payload)
  },
  setSubscription({ commit }, payload) {
    commit('setSubscription', payload)
  },
  setSignature({ commit }, payload) {
    commit('setSignature', payload)
  },
  setSpouseSubscription({ commit }, payload) {
    commit('setSpouseSubscription', payload)
  },
  setIsContactMailSend({ commit }, payload) {
    commit('setIsContactMailSend', payload)
  },
}

export default actions
