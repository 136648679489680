/* eslint-disable-next-line import/named */
import { toString } from 'lodash'

import { dateToYears } from './date'
import { type RootState } from '~/store/state'

const FALLBACK_STRING = 'Non renseigné'

// TODO:
// 'Sante indiv.' si le TGP est rempli sur www.april.fr
// 'Sante pro.' si le TGP est rempli sur pro.april.fr
export const marche = (_state: RootState) => 'Sante indiv.' // 'Sante pro.'

export const projectId = (state: RootState) => state.tgp.projectId

export const nbEnfants = (state: RootState) => state.tgp.childCounter

export const age = (state: RootState) =>
  state.tgp.dateNaissance && dateToYears(state.tgp.dateNaissance)

export const conjoint = (state: RootState) =>
  state.tgp.spouseContributorId ? 'oui' : 'non'

export const categorieFormule = (state: RootState) => {
  if (state.offers?.length === 1) return 'Suggérée'

  const index = state.offers?.indexOf(state.tgp.selectedOffer!)

  if (typeof index === 'undefined') return FALLBACK_STRING

  if (index > -1) return ['Économique', 'Suggérée', 'Premium'][index]
}

export const formuleSante = (state: RootState) => {
  const offer = state.tgp.selectedOffer

  if (!offer) return FALLBACK_STRING

  return `${offer.marketingProductId}-${offer.levelCode}`
}

export const formuleChoisie = (state: RootState) => {
  if (!state.tgp.selectedOffer) return FALLBACK_STRING

  return (
    toString(categorieFormule(state)) + ' - ' + toString(formuleSante(state))
  )
}

export const prime = (state: RootState) => {
  const offer = state.tgp.selectedOffer

  if (!offer) return FALLBACK_STRING

  return Math.round(
    (offer.globalPriceWithoutOption +
      state.tgp.optionPrice0 +
      state.tgp.optionPrice1) *
      12
  )
}

const hashValue = async function generateSHA256Hash(data: string | undefined) {
  if (!data) return FALLBACK_STRING

  const encoder = new TextEncoder()
  const dataBuffer = encoder.encode(data)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', dataBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}

export const hashEmail = async (state: RootState) =>
  await hashValue(state.tgp.contact?.mainInsuredEmail?.toLocaleLowerCase())

type Need =
  | 'dental'
  | 'hearingAid'
  | 'hospitalization'
  | 'medicalExpenses'
  | 'naturalMedecine'
  | 'optical'

export const needsValue = (state: RootState, need: Need) => {
  const basicHealthCovers: any = state.tgp.basicHealthCovers || {}

  switch (need) {
    case 'dental':
    case 'hospitalization':
    case 'medicalExpenses':
    case 'optical':
    case 'naturalMedecine':
      return basicHealthCovers[need]

    case 'hearingAid':
      // convert boolean to number
      return basicHealthCovers[need] ? 2 : 1
  }
}

const ROUTES = {
  '': 'etape-01-famille',
  '/': 'etape-01-famille',
  '/index.html': 'etape-01-famille',
  '/famille': 'etape-01-famille',
  '/couverture-actuelle': 'etape-02-couverture-actuelle',
  '/date-effet': 'etape-03-date-effet',
  '/date-naissance': 'etape-04-date-naissance',
  '/regime': 'etape-05-regime',
  '/statut-pro': 'etape-06-0-statut-pro',
  '/madelin': 'etape-06-1-madelin',
  '/conjoint': 'etape-06-2-conjoint',
  '/enfants': 'etape-06-3-enfants',
  '/adresse': 'etape-07-adresse',
  '/adresse-details': 'etape-07-1-adresse-details',
  '/niveaux-couverture': 'etape-08-besoins',
  '/contact': 'etape-09-contact',
  '/offres': 'etape-10-0-offres',
  '/offres/:projectId': 'etape-10-0-comparateur-offres',
  '/comparateur-couverture-actuelle':
    'etape-11-1-comparateur-couverture-actuelle',
  '/comparateur-date-effet': 'etape-11-2-comparateur-date-effet',
  '/comparateur-madelin': 'etape-11-3-comparateur-madelin',
  '/panier': 'etape-11-panier',
  '/souscription-assure-principal': 'etape-12-0-souscription',
  '/souscription/:projectId': 'etape-11-0-panier',
  '/souscription-conjoint': 'etape-12-1-souscription-conjoint',
  '/souscription-enfant/1': 'etape-12-2-souscription-enfant-1',
  '/souscription-enfant/2': 'etape-12-2-souscription-enfant-2',
  '/souscription-enfant/3': 'etape-12-2-souscription-enfant-3',
  '/souscription-enfant/4': 'etape-12-2-souscription-enfant-4',
  '/souscription-enfant/5': 'etape-12-2-souscription-enfant-5',
  '/souscription-enfant/6': 'etape-12-2-souscription-enfant-6',
  '/detail-adresse': 'etape-13-0-detail-adresse',
  '/assureur-a-resilier': 'etape-13-1-assureur-a-resilier',
  '/iban': 'etape-14-iban',
  '/prelevements': 'etape-15-prelevements',
  '/confirmation-sms': 'etape-16-confirmation-sms',
  '/signature': 'etape-17-signature',
  '/adhesion-validee/:orderId': 'etape-18-adhesion-validee',
} as const

type Route = keyof typeof ROUTES

export const libelleEtape = (route: Route | string) =>
  ROUTES[route as Route] || route
