
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { StepState } from '~~/store/steps/state'

export default defineComponent({
  name: 'StickyBottom',
  data() {
    return {
      disabled: false,
      ctaNext: 'Suivant',
    }
  },
  computed: {
    ...(mapGetters({
      previousStep: 'steps/previousStep',
      currentStep: 'steps/currentStep',
    }) as {
      previousStep: () => string
      currentStep: () => StepState['currentStep']
    }),
  },
  watch: {
    $route(to) {
      if (to.path === '/signature') {
        this.ctaNext = 'Signer mon contrat'
      } else {
        this.ctaNext = 'Suivant'
      }
    },
  },
  mounted() {
    // receive disabled value from Step component
    this.$nuxt.$on('form-disabled', (disabled: boolean) => {
      this.disabled = disabled
    })

    if ((this.currentStep as string) === 'signature') {
      this.ctaNext = 'Signer mon contrat'
    }
  },
  beforeDestroy() {
    // removing eventBus listener
    this.$nuxt.$off('form-disabled')
  },
  methods: {
    nextClick() {
      // send click on next to Step component
      this.$nuxt.$emit('form-next')
    },
    prevClick() {
      // send click on prev to Step component
      this.$nuxt.$emit('form-prev')
    },
  },
})
