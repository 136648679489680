import moment from 'moment'

export const DATE_FORMAT = 'DD/MM/YYYY'

export const momentDate = (value: any) => moment(value, DATE_FORMAT, true)

export const dateIsBefore = (value: any, days: number) =>
  momentDate(value).diff(moment(), 'days', true) < days

export const dateIsAfter = (value: any, days: number) =>
  momentDate(value).startOf('day').diff(moment().startOf('day'), 'days') >= days

export const apiDate = (value: any) =>
  moment(value, DATE_FORMAT).format('YYYY-MM-DD')

// example: 30 avr. 2024
export const longFormat = (value: any) =>
  moment(value, DATE_FORMAT).locale('fr').format('ll')

export const dateEffetIsValid = (
  date: string,
  hasIndividualPolicyInsurance: boolean,
  isCancelableInsurance: boolean,
  valideHour: number
) => {
  if (hasIndividualPolicyInsurance && isCancelableInsurance) {
    // hasIndividualPolicyInsurance === true && isCancelableInsurance === true
    return dateIsAfter(date, 34)
  } else {
    return dateIsAfter(date, valideHour)
  }
}

export const dateToYears = (date: string) =>
  moment().diff(momentDate(date), 'years')

export const isValidHour = (daysHour: any) => {
  const now = moment()
  const days = now.days()
  const defaultHour = '20:00'
  const limitHour = () => {
    const date = now.format('YYYY-MM-DD')
    switch (days) {
      case 1:
        return moment(date + ` ${daysHour?.lundi[0]?.text ?? defaultHour}`)
      case 2:
        return moment(date + ` ${daysHour?.mardi[0]?.text ?? defaultHour}`)
      case 3:
        return moment(date + ` ${daysHour?.mercredi[0]?.text ?? defaultHour}`)
      case 4:
        return moment(date + ` ${daysHour?.jeudi[0]?.text ?? defaultHour}`)
      case 5:
        return moment(date + ` ${daysHour?.vendredi[0]?.text ?? defaultHour}`)
      case 6:
        return moment(date + ` ${daysHour?.samedi[0]?.text ?? defaultHour}`)
      case 7:
        return moment(date + ` ${daysHour?.dimanche[0]?.text ?? defaultHour}`)
      default:
        return moment(date + ` ${defaultHour}`)
    }
  }
  return limitHour().isAfter(now)
}

export function calculateAge(birthdate: string) {
  const today = new Date()
  const birthDate = new Date(birthdate)

  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()

  // If the birth month hasn't occurred yet this year, or if it's the same month but the birth day hasn't occurred yet
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--
  }

  return age
}

export function isToday(date: any) {
  return moment(date).isSame(moment(), 'day')
}
export function isTomorrow(date: any) {
  return moment(date).isSame(moment().add(1, 'day'), 'day')
}
