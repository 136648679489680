
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AprilBody',
  props: {
    hiddenHeader: {
      type: Boolean,
      default: false,
    },
    hideStepper: {
      type: Boolean,
      default: false,
    },
  },
  head() {
    return {
      // inject HTML attributes on <body />
      // add class for theme blue
      bodyAttrs: {
        class: 'theme-blue',
      },
    }
  },
})
