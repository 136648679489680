import { render, staticRenderFns } from "./PopinGlobalError.vue?vue&type=template&id=78ab002d"
import script from "./PopinGlobalError.vue?vue&type=script&setup=true&lang=ts"
export * from "./PopinGlobalError.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsConfirmationBlock: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/confirmation-block/confirmation-block.vue').default,MoleculesPopin: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/popin/popin.vue').default})
