import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7fc4ad10 = () => interopDefault(import('./prismic/pages/preview.vue' /* webpackChunkName: "" */))
const _43672aee = () => interopDefault(import('../pages/adresse.vue' /* webpackChunkName: "pages/adresse" */))
const _005b25c2 = () => interopDefault(import('../pages/assureur-a-resilier.vue' /* webpackChunkName: "pages/assureur-a-resilier" */))
const _2691b05c = () => interopDefault(import('../pages/couverture-actuelle.vue' /* webpackChunkName: "pages/couverture-actuelle" */))
const _054ebaea = () => interopDefault(import('../pages/date-effet.vue' /* webpackChunkName: "pages/date-effet" */))
const _5d8d24b6 = () => interopDefault(import('../pages/madelin.vue' /* webpackChunkName: "pages/madelin" */))
const _353aed52 = () => interopDefault(import('../pages/confirmation-sms.vue' /* webpackChunkName: "pages/confirmation-sms" */))
const _518acf44 = () => interopDefault(import('../pages/conjoint.vue' /* webpackChunkName: "pages/conjoint" */))
const _79b33d7a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3278cb7c = () => interopDefault(import('../pages/date-naissance.vue' /* webpackChunkName: "pages/date-naissance" */))
const _6fa8116e = () => interopDefault(import('../pages/detail-adresse.vue' /* webpackChunkName: "pages/detail-adresse" */))
const _23feb2a3 = () => interopDefault(import('../pages/enfants.vue' /* webpackChunkName: "pages/enfants" */))
const _25d89248 = () => interopDefault(import('../pages/famille.vue' /* webpackChunkName: "pages/famille" */))
const _2d4871c8 = () => interopDefault(import('../pages/iban.vue' /* webpackChunkName: "pages/iban" */))
const _3cf6ddd7 = () => interopDefault(import('../pages/niveaux-couverture.vue' /* webpackChunkName: "pages/niveaux-couverture" */))
const _098fa92a = () => interopDefault(import('../pages/offres/index.vue' /* webpackChunkName: "pages/offres/index" */))
const _79c38a80 = () => interopDefault(import('../pages/prelevements.vue' /* webpackChunkName: "pages/prelevements" */))
const _262263c3 = () => interopDefault(import('../pages/regime.vue' /* webpackChunkName: "pages/regime" */))
const _fd12856a = () => interopDefault(import('../pages/souscription-assure-principal.vue' /* webpackChunkName: "pages/souscription-assure-principal" */))
const _12e3fb6b = () => interopDefault(import('../pages/souscription-conjoint.vue' /* webpackChunkName: "pages/souscription-conjoint" */))
const _7eb650e9 = () => interopDefault(import('../pages/statut-pro.vue' /* webpackChunkName: "pages/statut-pro" */))
const _1dc72ea8 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _2fd110ef = () => interopDefault(import('../pages/adhesion-validee/_orderId.vue' /* webpackChunkName: "pages/adhesion-validee/_orderId" */))
const _1801143b = () => interopDefault(import('../pages/devis/_projectId.vue' /* webpackChunkName: "pages/devis/_projectId" */))
const _4ad136de = () => interopDefault(import('../pages/emailconfirmation/_orderId.vue' /* webpackChunkName: "pages/emailconfirmation/_orderId" */))
const _193a0426 = () => interopDefault(import('../pages/offres/_projectId.vue' /* webpackChunkName: "pages/offres/_projectId" */))
const _7206aa85 = () => interopDefault(import('../pages/souscription-enfant/_index.vue' /* webpackChunkName: "pages/souscription-enfant/_index" */))
const _a8ca220c = () => interopDefault(import('../pages/souscription/_projectId.vue' /* webpackChunkName: "pages/souscription/_projectId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/preview",
    component: _7fc4ad10,
    name: "prismic-preview"
  }, {
    path: "/adresse",
    component: _43672aee,
    name: "adresse___fr-fr"
  }, {
    path: "/assureur-a-resilier",
    component: _005b25c2,
    name: "assureur-a-resilier___fr-fr"
  }, {
    path: "/comparateur-adresse",
    component: _43672aee,
    name: "comparateur-adresse___fr-fr"
  }, {
    path: "/comparateur-couverture-actuelle",
    component: _2691b05c,
    name: "comparateur-couverture-actuelle___fr-fr"
  }, {
    path: "/comparateur-date-effet",
    component: _054ebaea,
    name: "comparateur-date-effet___fr-fr"
  }, {
    path: "/comparateur-madelin",
    component: _5d8d24b6,
    name: "comparateur-madelin___fr-fr"
  }, {
    path: "/confirmation-sms",
    component: _353aed52,
    name: "confirmation-sms___fr-fr"
  }, {
    path: "/conjoint",
    component: _518acf44,
    name: "conjoint___fr-fr"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___fr-fr"
  }, {
    path: "/couverture-actuelle",
    component: _2691b05c,
    name: "couverture-actuelle___fr-fr"
  }, {
    path: "/date-effet",
    component: _054ebaea,
    name: "date-effet___fr-fr"
  }, {
    path: "/date-naissance",
    component: _3278cb7c,
    name: "date-naissance___fr-fr"
  }, {
    path: "/detail-adresse",
    component: _6fa8116e,
    name: "detail-adresse___fr-fr"
  }, {
    path: "/enfants",
    component: _23feb2a3,
    name: "enfants___fr-fr"
  }, {
    path: "/famille",
    component: _25d89248,
    name: "famille___fr-fr"
  }, {
    path: "/iban",
    component: _2d4871c8,
    name: "iban___fr-fr"
  }, {
    path: "/index.html",
    component: _25d89248,
    name: "index.html___fr-fr"
  }, {
    path: "/madelin",
    component: _5d8d24b6,
    name: "madelin___fr-fr"
  }, {
    path: "/niveaux-couverture",
    component: _3cf6ddd7,
    name: "niveaux-couverture___fr-fr"
  }, {
    path: "/offres",
    component: _098fa92a,
    name: "offres___fr-fr"
  }, {
    path: "/prelevements",
    component: _79c38a80,
    name: "prelevements___fr-fr"
  }, {
    path: "/regime",
    component: _262263c3,
    name: "regime___fr-fr"
  }, {
    path: "/souscription-assure-principal",
    component: _fd12856a,
    name: "souscription-assure-principal___fr-fr"
  }, {
    path: "/souscription-conjoint",
    component: _12e3fb6b,
    name: "souscription-conjoint___fr-fr"
  }, {
    path: "/statut-pro",
    component: _7eb650e9,
    name: "statut-pro___fr-fr"
  }, {
    path: "/test",
    component: _1dc72ea8,
    name: "test___fr-fr"
  }, {
    path: "/",
    component: _25d89248,
    name: "index___fr-fr"
  }, {
    path: "/adhesion-validee/:orderId?",
    component: _2fd110ef,
    name: "adhesion-validee-orderId___fr-fr"
  }, {
    path: "/devis/:projectId?",
    component: _1801143b,
    name: "devis-projectId___fr-fr"
  }, {
    path: "/emailconfirmation/:orderId?",
    component: _4ad136de,
    name: "emailconfirmation-orderId___fr-fr"
  }, {
    path: "/offres/:projectId",
    component: _193a0426,
    name: "offres-projectId___fr-fr"
  }, {
    path: "/souscription-enfant/:index",
    component: _7206aa85,
    name: "souscription-enfant___fr-fr"
  }, {
    path: "/souscription/:projectId?",
    component: _a8ca220c,
    name: "souscription-projectId___fr-fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
