
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  async fetch(this: any) {
    const footerPrismic = await this.$prismic.api.getSingle('tgps_footer')

    if (footerPrismic) {
      const formattedData = {
        title: this.$prismic.asHTML(footerPrismic.data?.title),
        links:
          footerPrismic.data?.links?.map(
            (link: {
              label: any
              link: { url: any; link_type: string }
              cookies_opener: any
            }) => ({
              label: link.label,
              link: {
                href: link.link.url,
                external: (link.link.link_type === 'Web').toString(),
                target: '_blank',
              },
              cookiesOpener: link.cookies_opener,
            })
          ) || [],
      }

      this.$store.dispatch('prismic/setFooterData', formattedData)
    }
  },
  computed: {
    ...(mapGetters({ footerData: 'prismic/footerData' }) as any),
  },
  methods: {
    openCookies() {
      ;(window as any)._axcb.push(function (sdk: any) {
        const firstInput = sdk.overlayNode.querySelector('input')
        !firstInput && sdk.openCookies()
      })
    },
  },
})
