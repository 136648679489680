import { type BasicHealthCoversForm } from '~/types/basic-health-covers'
import { DropdownOption, type CountryCode } from '~/types/global'

export type FormState = {
  adresseAutocomplete?: string
  addressNumber?: string
  addressRoad?: string
  addressDetail?: string
  addressZipCode?: string
  basicHealthCovers: BasicHealthCoversForm
  child0Birthdate?: string
  child0MandatoryScheme?: string
  child1Birthdate?: string
  child1MandatoryScheme?: string
  child2Birthdate?: string
  child2MandatoryScheme?: string
  child3Birthdate?: string
  child3MandatoryScheme?: string
  child4Birthdate?: string
  child4MandatoryScheme?: string
  child5Birthdate?: string
  child5MandatoryScheme?: string
  childCivilityRefId?: string
  childFirstName?: string
  childSurname?: string
  childFrenchSocialSecurityNumber?: string
  childAttachmentContributorId?: string
  childHasAttachedSocialSecurity?: boolean
  children: {
    civilityRefId?: string
    firstName?: string
    surname?: string
    frenchSocialSecurityNumber?: string
    attachmentContributorId?: string
    hasAttachedSocialSecurity?: boolean
  }[]
  city?: string
  contact: {
    civility?: string
    countryCode?: CountryCode
    firstName?: string
    surname?: string
    mainInsuredEmail?: string
    optin?: boolean
    phoneNumber?: string
  }
  currentChild: number
  currentInsurer?: DropdownOption
  dateEffet?: string
  dateNaissance?: string
  deductibilityMadelinLaw?: boolean
  hasIndividualPolicyInsurance?: boolean
  iban?: string
  isCancelableInsurance?: boolean
  freelancer?: boolean
  paymentDay?: DropdownOption
  peopleComposition?: string
  periodicity?: DropdownOption
  policyNumber?: string
  regime?: string
  spouseBirthdate?: string
  spouseMandatoryScheme?: string
  statutPro?: string
  birthName?: string
  maritalStatus?: DropdownOption
  nationality?: DropdownOption
  birthPlaceCountry?: DropdownOption
  birthPlaceDepartment?: DropdownOption
  birthPlaceCityDropdown?: DropdownOption
  birthPlaceCityInput?: string
  socialSecurityNumber?: string
  signatureCountryCode?: CountryCode
  signaturePhoneNumber?: string
  spouse: {
    civility?: string
    firstName?: string
    surname?: string
    maidenName?: string
    socialSecurityNumber?: string
    nationality?: DropdownOption
    birthPlaceCountry?: DropdownOption
    birthPlaceDepartment?: DropdownOption
    birthPlaceCityDropdown?: DropdownOption
    birthPlaceCityInput?: string
  }
}

const state = (): FormState => ({
  adresseAutocomplete: undefined,
  addressNumber: undefined,
  addressRoad: undefined,
  addressDetail: undefined,
  addressZipCode: undefined,
  basicHealthCovers: {
    hospitalization: 0,
    medicalExpenses: 0,
    optical: 0,
    dental: 0,
    hearingAid: 0,
    naturalMedecine: false,
  },
  birthName: undefined,
  birthPlaceCityDropdown: undefined,
  birthPlaceCityInput: undefined,
  birthPlaceCountry: undefined,
  birthPlaceDepartment: undefined,
  child0Birthdate: undefined,
  child0MandatoryScheme: undefined,
  child1Birthdate: undefined,
  child1MandatoryScheme: undefined,
  child2Birthdate: undefined,
  child2MandatoryScheme: undefined,
  child3Birthdate: undefined,
  child3MandatoryScheme: undefined,
  child4Birthdate: undefined,
  child4MandatoryScheme: undefined,
  child5Birthdate: undefined,
  child5MandatoryScheme: undefined,
  childCivilityRefId: undefined,
  childFirstName: undefined,
  childSurname: undefined,
  childFrenchSocialSecurityNumber: undefined,
  childAttachmentContributorId: undefined,
  childHasAttachedSocialSecurity: undefined,
  children: [],
  city: undefined,
  contact: {},
  currentChild: 0,
  currentInsurer: undefined,
  dateEffet: undefined,
  dateNaissance: undefined,
  deductibilityMadelinLaw: undefined,
  hasIndividualPolicyInsurance: undefined,
  iban: undefined,
  isCancelableInsurance: undefined,
  freelancer: undefined,
  maritalStatus: undefined,
  nationality: undefined,
  paymentDay: undefined,
  peopleComposition: undefined,
  periodicity: undefined,
  policyNumber: undefined,
  regime: undefined,
  socialSecurityNumber: undefined,
  spouse: {
    civility: undefined,
    firstName: undefined,
    surname: undefined,
    maidenName: undefined,
    socialSecurityNumber: undefined,
    nationality: undefined,
    birthPlaceCityDropdown: undefined,
    birthPlaceCityInput: undefined,
    birthPlaceCountry: undefined,
    birthPlaceDepartment: undefined,
  },
  spouseBirthdate: undefined,
  spouseMandatoryScheme: undefined,
  statutPro: undefined,
  signatureCountryCode: undefined,
  signaturePhoneNumber: undefined,
})

export default state
