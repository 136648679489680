import VuexPersist from 'vuex-persist'
import type { Plugin } from '@nuxt/types'
/* eslint-disable-next-line import/named */
import { isNil } from 'lodash'
import { type RootState } from '~/store/state'
import { cookieValuetoTgpState } from '~/utils/cookies'

// related to Vuex store management in app
export default <Plugin>function (context, _inject) {
  const { store, $config, $cookies } = context
  const cookie = $cookies('__april_project_situation')

  // get key given in runtime config
  const key = $config.public.localStorageKey

  // create cookie if it does not exist
  if (isNil(cookie.value)) {
    cookie.value = ''
  }

  // see https://www.npmjs.com/package/vuex-persist for usage
  new VuexPersist({
    key,
    reducer: (state: RootState) => {
      return { tgp: state.tgp }
    },
    restoreState: (key, storage) => {
      if (!storage) {
        // case when no storage session but a cookie
        if (cookie.value) return { tgp: cookieValuetoTgpState(cookie.value) }

        // case when nothing
        return {}
      }

      // case when storage
      const value = storage.getItem(key)

      if (!value) {
        // case when storage empty session but a cookie
        if (cookie.value) return { tgp: cookieValuetoTgpState(cookie.value) }

        // case when nothing
        return {}
      }

      // copy/paste from built-in mechanism for local/session storage
      if (typeof value === 'string') {
        return JSON.parse(value || '{}')
      } else {
        return value || {}
      }
    },
    storage: window.sessionStorage,
  }).plugin(store)
}
