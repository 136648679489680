import { type MutationTree } from 'vuex'
import { type RootState } from './state'
import { type EkomiPayload } from '~/api-clients/ekomi'
import { type Offer } from '~/types/offers'

export const mutations: MutationTree<RootState> = {
  setCounter: (state, value: number) => (state.counter = value),
  setEkomi: (state, value: EkomiPayload) => {
    state.ekomi = value
  },
  setOffers: (state, offers: Offer[]) => (state.offers = offers),
  setInsurers: (state, payload) => (state.insurers = payload),
  setFetchingOffers: (state, value: boolean) => (state.fetchingOffers = value),
}

export default mutations
