import { Step } from '~/store/steps/state'
import { TgpState } from '~/store/tgp/state'

type SentryLevel = 'info' | 'warning' | 'error' | 'fatal'

type SentryPage =
  | Step
  | 'create-project'
  | 'devis/:projectId'
  | 'drawer-needs'
  | 'offres/:projectId'
  | 'souscription/:projectId'

type SentryContext = {
  level: SentryLevel
  tags: {
    page: SentryPage
  }
  extra: {
    mousflowSession: string
    offerId: string
    projectId: string
    signaturePhoneNumber?: string
  }
}

export const sentryContext = (
  tgpState: TgpState,
  page: SentryPage,
  level: SentryLevel = 'info',
  extra: Record<string, unknown> = {}
): SentryContext => {
  const mouseflowSession = (): string => {
    const sessionId = mouseflowSessionId()
    let link = 'missing'

    if (typeof sessionId !== 'undefined') {
      link = `https://eu.mouseflow.com/websites/d6ac2483-1c43-4679-8684-1ed044de98e1/recordings/${sessionId}/play`
    }

    return link
  }

  return {
    level,
    tags: {
      page,
    },
    extra: {
      mousflowSession: mouseflowSession(),
      offerId: tgpState.offerId || 'missing',
      projectId: tgpState.projectId || 'missing',
      ...extra,
    },
  }
}

const mouseflowSessionId = (): string | undefined => {
  let sessionId

  try {
    if (typeof window.mouseflow !== 'undefined') {
      sessionId = window.mouseflow.getSessionId()
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Error getting mouseflow session id:', error)
  }

  return sessionId
}
