import { render, staticRenderFns } from "./StickyBottom.vue?vue&type=template&id=0e6cf493&scoped=true"
import script from "./StickyBottom.vue?vue&type=script&lang=ts"
export * from "./StickyBottom.vue?vue&type=script&lang=ts"
import style0 from "./StickyBottom.vue?vue&type=style&index=0&id=0e6cf493&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e6cf493",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCtaLink: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-link/cta-link.vue').default,AtomsCtaLabel: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-label/cta-label.vue').default})
