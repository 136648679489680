import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.from-entries.js";
var COOKIES = ['__april_project', '__april_project_situation', '__april_ref', 'cmpid'];
// related to Vuex store management in app
export default (function (_ref, inject) {
  var $config = _ref.$config;
  var cookieOptions = {
    domain: $config.public.cookieDomain,
    maxAge: $config.public.cookieTtl
  };
  var cookies = Object.fromEntries(COOKIES.map(function (cookie) {
    return [cookie, function (options) {
      return useCookie(cookie, _objectSpread(_objectSpread({}, cookieOptions), options));
    }];
  }));
  inject('cookies', function (cookie) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return cookies[cookie](options);
  });
});