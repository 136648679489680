import { type ActionTree } from 'vuex'
import { type FormState } from './state'

const actions: ActionTree<FormState, FormState> = {
  setAdresseAutocomplete({ commit }, payload) {
    commit('setAdresseAutocomplete', payload)
  },
  setAddressNumber({ commit }, payload) {
    commit('setAddressNumber', payload)
  },
  setAddressRoad({ commit }, payload) {
    commit('setAddressRoad', payload)
  },
  setAddressDetail({ commit }, payload) {
    commit('setAddressDetail', payload)
  },
  setAddressZipCode({ commit }, payload) {
    commit('setAddressZipCode', payload)
  },
  setBasicHealthCovers({ commit }, payload) {
    commit('setBasicHealthCovers', payload)
  },
  setChild0Birthdate({ commit }, payload) {
    commit('setChild0Birthdate', payload)
  },
  setChild0MandatoryScheme({ commit }, payload) {
    commit('setChild0MandatoryScheme', payload)
  },
  setChild1Birthdate({ commit }, payload) {
    commit('setChild1Birthdate', payload)
  },
  setChild1MandatoryScheme({ commit }, payload) {
    commit('setChild1MandatoryScheme', payload)
  },
  setChild2Birthdate({ commit }, payload) {
    commit('setChild2Birthdate', payload)
  },
  setChild2MandatoryScheme({ commit }, payload) {
    commit('setChild2MandatoryScheme', payload)
  },
  setChild3Birthdate({ commit }, payload) {
    commit('setChild3Birthdate', payload)
  },
  setChild3MandatoryScheme({ commit }, payload) {
    commit('setChild3MandatoryScheme', payload)
  },
  setChild4Birthdate({ commit }, payload) {
    commit('setChild4Birthdate', payload)
  },
  setChild4MandatoryScheme({ commit }, payload) {
    commit('setChild4MandatoryScheme', payload)
  },
  setChild5Birthdate({ commit }, payload) {
    commit('setChild5Birthdate', payload)
  },
  setChild5MandatoryScheme({ commit }, payload) {
    commit('setChild5MandatoryScheme', payload)
  },
  setChildCivilityRefId({ commit }, payload) {
    commit('setChildCivilityRefId', payload)
  },
  setChildFirstName({ commit }, payload) {
    commit('setChildFirstName', payload)
  },
  setChildSurname({ commit }, payload) {
    commit('setChildSurname', payload)
  },
  setChildFrenchSocialSecurityNumber({ commit }, payload) {
    commit('setChildFrenchSocialSecurityNumber', payload)
  },
  setChildAttachmentContributorId({ commit }, payload) {
    commit('setChildAttachmentContributorId', payload)
  },
  setChildHasAttachedSocialSecurity({ commit }, payload) {
    commit('setChildHasAttachedSocialSecurity', payload)
  },
  setCity({ commit }, payload) {
    commit('setCity', payload)
  },
  setCurrentInsurer({ commit }, payload) {
    commit('setCurrentInsurer', payload)
  },
  setContact({ commit }, payload) {
    commit('setContact', payload)
  },
  setCurrentChild({ commit }, payload) {
    commit('setCurrentChild', payload)
  },
  setDateEffet({ commit }, payload) {
    commit('setDateEffet', payload)
  },
  setDateNaissance({ commit }, payload) {
    commit('setDateNaissance', payload)
  },
  setDeductibilityMadelinLaw({ commit }, payload) {
    commit('setDeductibilityMadelinLaw', payload)
  },
  setHasIndividualPolicyInsurance({ commit }, payload) {
    commit('setHasIndividualPolicyInsurance', payload)
  },
  setIban({ commit }, payload) {
    commit('setIban', payload)
  },
  setIsCancelableInsurance({ commit }, payload) {
    commit('setIsCancelableInsurance', payload)
  },
  setFreelancer({ commit }, payload) {
    commit('setFreelancer', payload)
  },
  setPaymentDay({ commit }, payload) {
    commit('setPaymentDay', payload)
  },
  setPeopleComposition({ commit }, payload) {
    commit('setPeopleComposition', payload)
  },
  setPeriodicity({ commit }, payload) {
    commit('setPeriodicity', payload)
  },
  setRegime({ commit }, payload) {
    commit('setRegime', payload)
  },
  setSpouseBirthdate({ commit }, payload) {
    commit('setSpouseBirthdate', payload)
  },
  setSpouseMandatoryScheme({ commit }, payload) {
    commit('setSpouseMandatoryScheme', payload)
  },
  setStatutPro({ commit }, payload) {
    commit('setStatutPro', payload)
  },
  setBirthName({ commit }, payload) {
    commit('setBirthName', payload)
  },
  setMaritalStatus({ commit }, payload) {
    commit('setMaritalStatus', payload)
  },
  setNationality({ commit }, payload) {
    commit('setNationality', payload)
  },
  setBirthPlaceCountry({ commit }, payload) {
    commit('setBirthPlaceCountry', payload)
  },
  setBirthPlaceDepartment({ commit }, payload) {
    commit('setBirthPlaceDepartment', payload)
  },
  setBirthPlaceCityDropdown({ commit }, payload) {
    commit('setBirthPlaceCityDropdown', payload)
  },
  setBirthPlaceCityInput({ commit }, payload) {
    commit('setBirthPlaceCityInput', payload)
  },
  setPolicyNumber({ commit }, payload) {
    commit('setPolicyNumber', payload)
  },
  setSocialSecurityNumber({ commit }, payload) {
    commit('setSocialSecurityNumber', payload)
  },
  setSignatureCountryCode({ commit }, payload) {
    commit('setSignatureCountryCode', payload)
  },
  setSignaturePhoneNumber({ commit }, payload) {
    commit('setSignaturePhoneNumber', payload)
  },
  setSpouseCivility({ commit }, payload) {
    commit('setSpouseCivility', payload)
  },
  setSpouseFirstName({ commit }, payload) {
    commit('setSpouseFirstName', payload)
  },
  setSpouseSurname({ commit }, payload) {
    commit('setSpouseSurname', payload)
  },
  setSpouseMaidenName({ commit }, payload) {
    commit('setSpouseMaidenName', payload)
  },
  setSpouseNationality({ commit }, payload) {
    commit('setSpouseNationality', payload)
  },
  setSpouseSocialSecurityNumber({ commit }, payload) {
    commit('setSpouseSocialSecurityNumber', payload)
  },
  setSpouseBirthPlaceCityDropdown({ commit }, payload) {
    commit('setSpouseBirthPlaceCityDropdown', payload)
  },
  setSpouseBirthPlaceCityInput({ commit }, payload) {
    commit('setSpouseBirthPlaceCityInput', payload)
  },
  setSpouseBirthPlaceCountry({ commit }, payload) {
    commit('setSpouseBirthPlaceCountry', payload)
  },
  setSpouseBirthPlaceDepartment({ commit }, payload) {
    commit('setSpouseBirthPlaceDepartment', payload)
  },
}

export default actions
