// 'etape-01-famille'
// 'etape-02-couverture-actuelle'
// 'etape-03-date-effet'
// 'etape-04-date-naissance'
// 'etape-05-regime'
// 'etape-06-statut-pro'
// 'etape-06-1-conjoint'
// 'etape-06-2-enfants'
// 'etape-07-adresse'
// 'etape-07-1-adresse-details'
// 'etape-08-niveaux-couverture'
// 'etape-09-contact
// 'etape-10-offres
// 'etape-10-1-options'
// 'etape-10-2-madelin'
// 'etape-11-souscription'
// 'etape-11-1-souscription-conjoint'
// 'etape-11-2-souscription-enfant'
// 'etape-12-iban'
// 'etape-13-prelevements'
// 'etape-14-confirmation-sms'
// 'etape-15-signature'
// 'etape-16-adhesion-validee'

import { madelinAvailable } from '../../utils/navigation'
import { TgpState } from '../tgp/state'

export type Step =
  | 'famille'
  | 'couverture-actuelle'
  | 'date-effet'
  | 'date-naissance'
  | 'regime'
  | 'statut-pro'
  | 'conjoint'
  | 'enfants'
  | 'adresse'
  | 'niveaux-couverture'
  | 'contact'
  | 'offres'
  | 'madelin'
  | 'comparateur-couverture-actuelle'
  | 'comparateur-date-effet'
  | 'souscription-assure-principal'
  | 'souscription-conjoint'
  | 'souscription-enfant/1'
  | 'souscription-enfant/2'
  | 'souscription-enfant/3'
  | 'souscription-enfant/4'
  | 'souscription-enfant/5'
  | 'souscription-enfant/6'
  | 'detail-adresse'
  | 'assureur-a-resilier'
  | 'iban'
  | 'prelevements'
  | 'confirmation-sms'

type StepItem = Step | [Step, (tgpState: TgpState) => boolean]

export type StepSection = 'situation' | 'subscription'

// list of all the steps of the tunnel
// for each of them:
// - mandatory step: only its name
// - conditional step: an array with its name, and a function that takes the state TGP and returns a boolean
export const STEPS: { [section in StepSection]: StepItem[] } = {
  situation: [
    'famille',
    'couverture-actuelle',
    'date-effet',
    'date-naissance',
    'regime',
    'statut-pro',
    ['madelin', (tgpState: TgpState) => madelinAvailable(tgpState)],
    [
      'conjoint',
      (tgpState: TgpState) =>
        ['couple', 'coupleChild'].includes(tgpState.peopleComposition!),
    ],
    [
      'enfants',
      (tgpState: TgpState) =>
        ['aloneChild', 'coupleChild'].includes(tgpState.peopleComposition!),
    ],
    'adresse',
    'niveaux-couverture',
    'contact',
    'offres',
  ],
  subscription: [
    'souscription-assure-principal',
    [
      'souscription-conjoint',
      (tgpState: TgpState) =>
        ['couple', 'coupleChild'].includes(tgpState.peopleComposition!),
    ],
    ...[...Array(6)].map(
      (_, index) =>
        [
          `souscription-enfant/${index + 1}`,
          (tgpState: TgpState) =>
            ['aloneChild', 'coupleChild'].includes(
              tgpState.peopleComposition!
            ) && index < tgpState.childCounter,
        ] as unknown as StepItem
    ),
    'detail-adresse',
    [
      'assureur-a-resilier',
      (tgpState: TgpState) => {
        return !!(
          tgpState.projectSituation?.hasIndividualPolicyInsurance === 'true' &&
          tgpState.projectSituation?.isCancelableInsurance === 'true'
        )
      },
    ],
    'iban',
    'prelevements',
    'confirmation-sms',
  ],
}

export type StepState = {
  currentSection: StepSection
  currentStep: Step
  nbSteps: number
}

const state = (): StepState => ({
  currentSection: 'situation',
  currentStep: 'famille',
  nbSteps: 0,
})

export default state
