import { isNil, sortBy } from 'lodash' /* eslint-disable-line import/named */
import { type Category, columnName } from './offers'
import { type Option, type StatutProOptions } from '~/types/statut-pro'
import {
  type BasicHealthCoversForm,
  type BasicHealthCoversTgp,
} from '~/types/basic-health-covers'
import { Offer } from '~~/types/offers'

export const booleanToString = (value?: boolean) => (value ? 'true' : 'false')
export const stringToBoolean = (value?: string) => value === 'true'

export const getStatutProOptions: (
  regime?: string,
  mandatorySchemes?: { id: string; professsional_status: Option[] }[]
) => StatutProOptions = (regime, mandatorySchemes) => {
  const noResult = {
    radioOptions: [],
    selectOptions: [],
  }

  if (isNil(regime)) {
    return noResult
  }

  const mandatoryScheme = mandatorySchemes?.find((item) => item.id === regime)

  if (isNil(mandatoryScheme)) {
    return noResult
  }

  const { professsional_status: professsionalStatus } = mandatoryScheme

  const options =
    sortBy(professsionalStatus as Option[], ['order']).map((item: Option) => ({
      ...item,
      value: item.id,
    })) || []

  return {
    radioOptions: options.slice(0, 4),
    selectOptions: options.slice(4),
  }
}

export const getStatutProLabel = (
  regime?: string,
  statut?: string,
  mandatorySchemes?: Array<any>
) => {
  const statutProOptions = getStatutProOptions(regime, mandatorySchemes)
  let label = null

  const radioOption = statutProOptions.radioOptions.find(
    (option) => option.id === statut
  )
  if (radioOption) {
    label = radioOption.label
  }

  if (!label) {
    const selectOption = statutProOptions.selectOptions.find(
      (option) => option.id === statut
    )
    if (selectOption) {
      label = selectOption.label
    }
  }

  return label
}

// used to highlight structured text from Maps API
export const formatStructuredText = (
  text: string,
  substrings: { offset: number; length: number }[]
) =>
  substrings
    ? substrings
        .sort((a, b) => b.offset - a.offset)
        .reduce(formatMatchedString, text)
    : text

export const formatMatchedString = (
  str: string,
  match: { offset: number; length: number }
) => {
  const before = str.substring(0, match.offset)

  const highlight = `<span class="matched">${str.substring(
    match.offset,
    match.offset + match.length
  )}</span>`

  const after = str.substring(match.offset + match.length, str.length)

  return before + highlight + after
}

// compute Levenshtein distance to find closer string in list to match city: the lowest the closest!
// from https://www.30secondsofcode.org/js/s/levenshtein-distance/
export const levenshteinDistance = (a: string, b: string) => {
  const s = a.toUpperCase()
  const t = b.toUpperCase()

  if (!s.length) return t.length
  if (!t.length) return s.length

  const arr = []

  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i]

    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            )
    }
  }

  return arr[t.length][s.length]
}

export const findByLevenshteinDistance = (
  word: string,
  list: any[],
  path: string
) => {
  const sorted = list.sort(
    (a, b) =>
      levenshteinDistance(word, a[path]) - levenshteinDistance(word, b[path])
  )

  return sorted[0]
}

export const basicHealthCoversToTgp = (
  basicHealthCovers: BasicHealthCoversForm
): BasicHealthCoversTgp => {
  return {
    hospitalization: (basicHealthCovers.hospitalization || 0) + 1,
    medicalExpenses: (basicHealthCovers.medicalExpenses || 0) + 1,
    optical: (basicHealthCovers.optical || 0) + 1,
    dental: (basicHealthCovers.dental || 0) + 1,
    hearingAid: basicHealthCovers.hearingAid === 1,
    naturalMedecine: basicHealthCovers.naturalMedecine,
  }
}

export const basicHealthCoversToForm = (
  basicHealthCovers: BasicHealthCoversTgp
): BasicHealthCoversForm => {
  return {
    hospitalization: (basicHealthCovers.hospitalization || 1) - 1,
    medicalExpenses: (basicHealthCovers.medicalExpenses || 1) - 1,
    optical: (basicHealthCovers.optical || 1) - 1,
    dental: (basicHealthCovers.dental || 1) - 1,
    hearingAid: basicHealthCovers.hearingAid ? 1 : 0,
    naturalMedecine: basicHealthCovers.naturalMedecine,
  }
}

export const getBasicHealthLevels = (offer: Offer) => {
  const getLevel = (category: Category) => {
    const value = columnName(offer, category)

    if (!value) {
      // eslint-disable-next-line no-console
      console.error('Missing value')
      return
    }

    switch (value) {
      case 'Minimum':
      case 'Non': // specific case: it happens for OPTIQUE in some offers (at least APRIL_ASP_ECOV3 ECO_01|01)
        return 1
      case 'Partiel':
        return 2
      case 'Renforcé':
        return 3
      case 'Maximum':
        return 4
      default:
        throw new Error(`Invalid value for category ${category}: ${value}`)
    }
  }

  return {
    hospitalization: getLevel('HOSPIT_CONV'),
    medicalExpenses: getLevel('SOINS_COURANTS'),
    optical: getLevel('OPTIQUE'),
    dental: getLevel('DENTAIRE'),
    hearingAid: columnName(offer, 'AUDIO') === 'Renforcé',
    naturalMedecine: columnName(offer, 'CONFORT_MED_NAT') === 'Oui',
  }
}

export const socialSecurityNumberMask = (defaultValue?: string) => {
  const isDefaultValueValid =
    defaultValue?.length === 5 || defaultValue?.length === 7

  return {
    mask: 'X AA MM DD 000 000 00',
    regexp:
      '^[12]\\s?[0-9]{2}\\s?[0-1][0-9]\\s?(2[AB]|[0-9]{2})\\s?[0-9]{3}\\s?[0-9]{3}\\s?[0-9]{2}$',
    lazy: false,
    blocks: {
      X: {
        mask: isDefaultValueValid ? `\\${defaultValue[0]}` : '0',
        placeholderChar: '_',
      },
      AA: {
        mask: isDefaultValueValid
          ? `\\${defaultValue[1]}\\${defaultValue[2]}`
          : '00',
        placeholderChar: '_',
      },
      MM: {
        mask: isDefaultValueValid
          ? `\\${defaultValue[3]}\\${defaultValue[4]}`
          : '00',
        placeholderChar: '_',
      },
      DD: {
        mask:
          isDefaultValueValid && defaultValue[5] && defaultValue[6]
            ? `\\${defaultValue[5]}\\${defaultValue[6]}`
            : '**',
        placeholderChar: '_',
      },
    },
  }
}
