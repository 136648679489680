import { type GetterTree } from 'vuex'
import { type RootState } from './state'

const getters: GetterTree<RootState, RootState> = {
  counter: (state) => state.counter,
  ekomi: (state) => state.ekomi,
  offers: (state) => state.offers,
  insurers: (state) => state.insurers,
  fetchingOffers: (state) => state.fetchingOffers,
}

export default getters
