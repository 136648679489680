import { type MutationTree } from 'vuex'
import { type PrismicState } from './state'

export const mutations: MutationTree<PrismicState> = {
  setAdvantagesPush: (state, payload: AdvantagesPushPayload) =>
    (state.advantagesPush = payload),
  setRatingsPushOnFamily: (state, value: any) =>
    (state.stepsData.family = {
      ...state.stepsData.family,
      ...{ ratingsPush: value },
    }),
  setContactPushOnFamily: (state, value: any) =>
    (state.stepsData.family = {
      ...state.stepsData.family,
      ...{
        contactPush: [...(state.stepsData.family.contactPush || []), value],
      },
    }),
  setFooterData: (state, value: any) => (state.footerData = value),
  setMandatorySchemes: (state, value: any) => (state.mandatorySchemes = value),
  setOffersContents: (state, value: any) => (state.offers.contents = value),
  setOffersLabels: (state, value: any) => (state.offers.labels = value),
  setOffersOptions: (state, value: any) => (state.offers.options = value),
  setOffersComparatorLabels: (state, value: any) =>
    (state.offers.comparatorLabels = value),
  setStepData: (
    state,
    payload: { key: keyof PrismicState['stepsData']; data: any }
  ) => ((state.stepsData as any)[payload.key] = payload.data),
}

export default mutations
