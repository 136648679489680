
export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
  mounted() {
    this.$sentry.captureException(new Error('Error page displayed'), {
      extra: {
        error: this.error,
        route: this.$route,
      },
    })
  },
}
