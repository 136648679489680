import { type FormState } from './form/state'
import { type PrismicState } from './prismic/state'
import { type StepState } from './steps/state'
import { type TgpState } from './tgp/state'
import { type EkomiPayload } from '~/api-clients/ekomi'
import { type Offer } from '~/types/offers'

type State = {
  counter: number
  ekomi: EkomiPayload
  offers?: Offer[]
  insurers?: {
    id: string
    value: string
    label: string
  }[]
  fetchingOffers: boolean
}

const state: () => State = () => ({
  counter: 0,
  ekomi: {},
  offers: undefined,
  insurers: [],
  fetchingOffers: false,
})

export type RootState = State & {
  form: FormState
  prismic: PrismicState
  steps: StepState
  tgp: TgpState
}

export default state
