import { Context } from '@nuxt/types'
import Vue from 'vue'
import { VNode } from 'vue/types/umd'
import {
  age,
  conjoint,
  formuleChoisie,
  hashEmail,
  libelleEtape,
  marche,
  nbEnfants,
  needsValue,
  prime,
  projectId,
} from '~/utils/tracking'
import { type Offer } from '~~/types/offers'

const EVENT = 'evenement_web'
const EVENT_CATEGORIE = 'TGP Santé'

enum EVENT_TYPE {
  actionInternaute = 'action_internaute',
  pageVue = 'page_vue',
}

type ConversionLabel =
  | 'Lead - optin'
  | 'MER - devis'
  | 'MER - devis actualisé'
  | 'MER - souscrire'
  | 'Adhésion validée'

export const pushCtaClick = (
  context: Pick<Context, '$gtm' | 'store'>,
  { action, label }: { action?: string; label: string },
  _el?: HTMLElement
) => {
  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: action || 'Clic CTA',
    event_libelle: label,
    marche: marche(state),
    project_id: projectId(state),
  }

  $gtm.push(data)
}

export const pushConversion = async (
  context: Pick<Context, '$gtm' | 'store'>,
  { label }: { label: ConversionLabel }
) => {
  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Conversion',
    event_libelle: label,
    marche: marche(state),
    projet_id: projectId(state),
    nb_enfants: nbEnfants(state),
    age: age(state),
    conjoint: conjoint(state),
    formule_choisie: formuleChoisie(state),
    prime_annuelle_ttc: prime(state),
    cle_adloop: await hashEmail(state),
  }

  $gtm.push(data)
}

export const pushNeeds = (context: Pick<Context, '$gtm' | 'store'>) => {
  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Clic CTA',
    event_libelle: 'Choix des niveaux de couverture',
    marche: marche(state),
    projet_id: projectId(state),
    jauge_soins_courants: needsValue(state, 'medicalExpenses'),
    jauge_hospitalisation: needsValue(state, 'hospitalization'),
    jauge_optique: needsValue(state, 'optical'),
    jauge_dentaire: needsValue(state, 'dental'),
    jauge_aides_auditives: needsValue(state, 'hearingAid'),
    jauge_medecines_douces: needsValue(state, 'naturalMedecine'),
  }

  $gtm.push(data)
}

export const pushDocument = (
  context: Pick<Context, '$gtm' | 'store'>,
  { documentName }: { documentName: string }
) => {
  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Clic CTA',
    event_libelle: `Téléchargement - ${documentName}`,
    marche: marche(state),
    projet_id: projectId(state),
  }

  $gtm.push(data)
}

export const pushOffers = (
  context: Pick<Context, '$gtm' | 'store'>,
  { offers }: { offers?: Offer[] }
) => {
  if (!offers || !offers.length) return

  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Visibilité élément',
    event_libelle: `${offers.length} offre(s) affichée(s)`,
    offres_affichees: offers
      .map((o) => `${o.marketingProductId}-${o.levelCode}`)
      .join('&'),
    marche: marche(state),
  }

  $gtm.push(data)
}

export const pushNoOffer = (context: Pick<Context, '$gtm' | 'store'>) => {
  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Visibilité élément',
    event_libelle: `0 offre affichée`,
    offres_affichees: '',
    marche: marche(state),
  }

  $gtm.push(data)
}

export const pushDateEffetUpdatedDisplayed = (
  context: Pick<Context, '$gtm' | 'store'>
) => {
  const { $gtm, store } = context || {}
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Visibilité élément',
    event_libelle: "date d'effet mise à jour",
    marche: marche(state),
    project_id: projectId(state),
  }

  $gtm.push(data)
}

export const pushPageView = (
  context: Pick<Context, '$gtm' | 'route' | 'store'>,
  { path }: { path?: string } = {}
) => {
  const { $gtm, route, store } = context || {}
  const { state } = store

  let formalPath =
    path || route.matched[0]?.path?.replace('?', '') || route.path

  // for this dynamic route, use path instead
  if (formalPath === '/souscription-enfant/:index') {
    formalPath = route.path
  }

  const pageViewData = {
    event: EVENT,
    event_type: EVENT_TYPE.pageVue,
    marche: marche(state),
    page_virtuelle: `/page-virtuelle/tgp_sante/${libelleEtape(formalPath)}`,
  }

  $gtm.push(pageViewData)

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Atteinte étape',
    event_libelle: libelleEtape(formalPath),
    marche: marche(state),
    projet_id: projectId(state),
  }

  $gtm.push(data)
}

export default (context: Context) => {
  const { gtmId } = context.$config
  context.$gtm.init(gtmId)

  let handleEvent: EventListenerOrEventListenerObject

  // bind tracking fired from components of the library
  Vue.directive('track', {
    bind: (
      el: HTMLElement,
      {
        value,
        arg,
      }: { value: { action?: string; label: string }; arg?: string },
      vnode: VNode
    ) => {
      handleEvent = () => {
        // check if `disableTrack` has been set to true on the Vue component: if so, return
        if ((vnode.context as any)?.disableTrack) return

        value && pushCtaClick(context, value, el)
      }

      arg && el.addEventListener(arg, handleEvent)
    },
    unbind: (el: HTMLElement, { arg }: { arg?: string }) => {
      arg && el.removeEventListener(arg, handleEvent)
    },
  })

  // bind tracking from clicks on specific links
  document.addEventListener(
    'click',
    (e) => {
      if (!e || !e.target) return

      const anchor = (e.target as HTMLElement).closest('a')

      if (!anchor) return

      const href = anchor.href || ''

      if (href.includes('ekomi.fr')) {
        pushCtaClick(context, { label: 'Lire les avis' }, anchor)
      }

      if (href.includes('tel:')) {
        pushCtaClick(context, { label: 'Téléphone' }, anchor)
      }
    },
    false
  )
}
